import { PermissionCheckerService } from 'abp-ng2-module';
import { AppSessionService } from '@shared/common/session/app-session.service';

import { Injectable } from '@angular/core';
import { AppMenu } from './app-menu';
import { AppMenuItem } from './app-menu-item';

@Injectable()
export class AppNavigationService {
  constructor(
    private _permissionCheckerService: PermissionCheckerService,
    private _appSessionService: AppSessionService
  ) { }

  getMenu(): AppMenu {
    return new AppMenu('MainMenu', 'MainMenu', [
      new AppMenuItem('Dashboard', 'Pages.Administration.Host.Dashboard', 'fa-duotone fa-house', '/app/admin/hostDashboard'),
      new AppMenuItem('Dashboard', 'Pages.Tenant.Dashboard', 'fa-duotone fa-house', '/app/main/dashboard'),
      new AppMenuItem('Tenants', 'Pages.Tenants', 'pi pi-users', '/app/admin/tenants'),
      new AppMenuItem('Editions', 'Pages.Editions', 'pi pi-book', '/app/admin/editions'),
      new AppMenuItem('Master', '', 'fa-duotone fa-gears', '', [],
        [
          new AppMenuItem('Branch', 'Pages.Branchs', 'fa-duotone fa-buildings', '/app/main/controlPanel/branchs'),
          new AppMenuItem('Departments', 'Pages.Departments', 'fa-duotone fa-building-columns', '/app/main/controlPanel/departments'),
          new AppMenuItem('Employees', 'Pages.Employees', 'fa-duotone fa-users', '/app/main/controlPanel/employees'),
          new AppMenuItem('Area', '', 'fa-duotone fa-money-bills', '/app/main/controlPanel/area'),
          new AppMenuItem('Device', 'Pages.Devices', 'fa-regular fa-mobile-retro', '/app/main/controlPanel/device'),
        ]
      ),

      new AppMenuItem('Leave', '', 'fa-duotone fa-house-leave', '', [],
        [
          new AppMenuItem('RequestLeaves', 'Pages.PayHeads', 'flaticon-more', '/app/main/leave/requestLeave'),
          new AppMenuItem('LeaveMasters', 'Pages.PayHeads', 'flaticon-more', '/app/main/leave/leaveMaster'),
          new AppMenuItem('AssignLeaves', 'Pages.AssignLeaves', 'flaticon-more', '/app/main/leave/assignLeave'),
          new AppMenuItem('ProvideLeaves', 'Pages.ProvideLeaves', 'flaticon-more', '/app/main/leave/provideLeave'),
          new AppMenuItem('Kajs', 'Pages.Kajs', 'flaticon-more', '/app/main/leave/kaj'),
        ]
      ),

      new AppMenuItem('Payroll', '', 'fa-duotone fa-hand-holding-dollar', '', [],
        [
          new AppMenuItem('Attendance', 'Pages.PayHeads', 'fa-duotone fa-user-check', '/app/main/payroll/attendance'),
          new AppMenuItem('PayHeads', 'Pages.PayHeads', 'fa-duotone fa-user-check', '/app/main/payroll/payHeads'),
          new AppMenuItem('WorkUnit', 'Pages.WorkUnits', 'fa-duotone fa-screen-users', '/app/main/payroll/workUnit'),
          new AppMenuItem('EmployeeTaxSetting', 'Pages.EmployeeTaxSettings', 'fa-duotone fa-credit-card', '/app/main/payroll/employeeTaxSetting'),
          new AppMenuItem('EmployeesCalender', 'Pages.Employees', 'fa-duotone fa-calendar-days', '/app/main/payroll/employeeCalendar'),
          new AppMenuItem('EmployeeOffice', 'Pages.AttendanceMasters', 'fa-duotone fa-buildings', '/app/main/payroll/EmployeeOffice'),
          new AppMenuItem('EmployeeShift', 'Pages.AttendanceMasters', 'fa-duotone fa-buildings', '/app/main/payroll/employeeShift'),
          new AppMenuItem('Employee Punch', 'Pages.EmployeeSalaryVoucherMasters', 'fa-duotone fa-memo', '/app/main/payroll/employeePunch'),
          new AppMenuItem('Manual StaffAttendance', 'Pages.AttendanceMasters', 'fa-duotone fa-rainbow', '/app/main/payroll/staffAttendance'),
          new AppMenuItem('BonusDeduction', 'Pages.EmployeeBonusDeductions', 'fa-duotone fa-circle-minus', '/app/main/payroll/BonusDeduction'),
          new AppMenuItem('AdvancePayment', 'Pages.EmployeeLoanAndAdvances', 'fa-duotone fa-dollar-sign', '/app/main/payroll/advancePayment'),
          new AppMenuItem('LopDeduction', 'Pages.EmployeeLopDeductionMasters', 'fa-duotone fa-user-minus', '/app/main/payroll/lopDeduction'),
          new AppMenuItem('EmployeeOvertime', 'Pages.AttendanceMasters', 'fa-duotone fa-user-clock', '/app/main/payroll/EmployeeOvertime'),
     //     new AppMenuItem('MonthlySalary', 'Pages.EmployeeSalaryVoucherMasters', 'fa-duotone fa-money-bills', '/app/main/payroll/monthlySalary'),
          new AppMenuItem('Monthly Salary New', 'Pages.EmployeeSalaryVoucherMasters', 'fa-duotone fa-money-bills', '/app/main/payroll/monthlySalaryNew'),
          new AppMenuItem('Single Attendance', 'Pages.AttendanceManual', 'fa-duotone fa-user-check', '/app/main/payroll/attendanceManual'),
          new AppMenuItem('Payslip', '', 'fa-duotone fa-user-check', '/app/main/payroll/paySlip'),
        ]
      ),

      new AppMenuItem('Reports', '', 'fa-duotone fa-sharp fa-thin fa-file-chart-column', '/app/main/reporting/reports'),

      new AppMenuItem('Setting', '', 'fa-duotone fa-gear', '', [],
        [
          new AppMenuItem('Address', 'Pages.Branchs', 'fa-duotone fa-location-dot', '/app/main/controlPanel/address'),
          new AppMenuItem('Notifications', '', 'flaticon-alarm', '', [],
            [
              new AppMenuItem(
                'Inbox',
                '',
                'flaticon-mail-1',
                '/app/notifications'
              ),
              new AppMenuItem(
                'MassNotifications',
                'Pages.Administration.MassNotification',
                'flaticon-paper-plane',
                '/app/admin/mass-notifications'
              ),
            ]
          ),
          new AppMenuItem(
            'OrganizationUnits',
            'Pages.Administration.OrganizationUnits',
            'fa-duotone fa-house-blank',
            '/app/admin/organization-units'
          ),
          new AppMenuItem('Roles', 'Pages.Administration.Roles', 'fa-duotone fa-person', '/app/admin/roles'),
          new AppMenuItem('Users', 'Pages.Administration.Users', 'fa-duotone fa-users', '/app/admin/users'),
          new AppMenuItem(
            'Languages',
            'Pages.Administration.Languages',
            'fa-duotone fa-language',
            '/app/admin/languages',
            ['/app/admin/languages/{name}/texts']
          ),
          new AppMenuItem(
            'AuditLogs',
            'Pages.Administration.AuditLogs',
            'fa-duotone fa-list-check',
            '/app/admin/auditLogs'
          ),
          new AppMenuItem(
            'Maintenance',
            'Pages.Administration.Host.Maintenance',
            'fa-duotone fa-sliders',
            '/app/admin/maintenance'
          ),
          new AppMenuItem(
            'Subscription',
            'Pages.Administration.Tenant.SubscriptionManagement',
            'fa-duotone fa-hand-pointer',
            '/app/admin/subscription-management'
          ),
          new AppMenuItem(
            'VisualSettings',
            'Pages.Administration.UiCustomization',
            'fa-duotone fa-eye',
            '/app/admin/ui-customization'
          ),
          new AppMenuItem(
            'WebhookSubscriptions',
            'Pages.Administration.WebhookSubscription',
            'fa-duotone fa-bell',
            '/app/admin/webhook-subscriptions'
          ),
          new AppMenuItem(
            'DynamicProperties',
            'Pages.Administration.DynamicProperties',
            'fa-duotone fa-star',
            '/app/admin/dynamic-property'
          ),
          new AppMenuItem(
            'Settings',
            'Pages.Administration.Host.Settings',
            'fa-duotone fa-gear',
            '/app/admin/hostSettings'
          ),
          new AppMenuItem(
            'Settings',
            'Pages.Administration.Tenant.Settings',
            'fa-duotone fa-gear',
            '/app/admin/tenantSettings'
          ),
        ]
      ),
    ]);
  }


  checkChildMenuItemPermission(menuItem): boolean {
    for (let i = 0; i < menuItem.items.length; i++) {
      let subMenuItem = menuItem.items[i];

      if (subMenuItem.permissionName === '' || subMenuItem.permissionName === null) {
        if (subMenuItem.route) {
          return true;
        }
      } else if (this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
        return true;
      }

      if (subMenuItem.items && subMenuItem.items.length) {
        let isAnyChildItemActive = this.checkChildMenuItemPermission(subMenuItem);
        if (isAnyChildItemActive) {
          return true;
        }
      }
    }
    return false;
  }

  showMenuItem(menuItem: AppMenuItem): boolean {
    if (
      menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' &&
      this._appSessionService.tenant &&
      !this._appSessionService.tenant.edition
    ) {
      return false;
    }

    let hideMenuItem = false;

    if (menuItem.requiresAuthentication && !this._appSessionService.user) {
      hideMenuItem = true;
    }

    if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
      hideMenuItem = true;
    }

    if (this._appSessionService.tenant || !abp.multiTenancy.ignoreFeatureCheckForHostUsers) {
      if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
        hideMenuItem = true;
      }
    }

    if (!hideMenuItem && menuItem.items && menuItem.items.length) {
      return this.checkChildMenuItemPermission(menuItem);
    }

    return !hideMenuItem;
  }

  getAllMenuItems(): AppMenuItem[] {
    let menu = this.getMenu();
    let allMenuItems: AppMenuItem[] = [];
    menu.items.forEach((menuItem) => {
      allMenuItems = allMenuItems.concat(this.getAllMenuItemsRecursive(menuItem));
    });

    return allMenuItems;
  }

  private getAllMenuItemsRecursive(menuItem: AppMenuItem): AppMenuItem[] {
    if (!menuItem.items) {
      return [menuItem];
    }

    let menuItems = [menuItem];
    menuItem.items.forEach((subMenu) => {
      menuItems = menuItems.concat(this.getAllMenuItemsRecursive(subMenu));
    });

    return menuItems;
  }
}
